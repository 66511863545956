.navStyle {
  background: #eaeaea;
}
.tabStyle {
  background: #ffffff;
}

.languageSelector {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
