.switch {
  border: 2px solid #ccc;
  border-radius: 20px;
  width: 60px;
  height: 28px;
  background: white;
  display: flex;
}

.switchSelector {
  border-radius: 20px;
  background: white;
  border: 1px solid #c0c0c0;
  height: 28px;
  width: 28px;
  margin-top: -2px;
  margin-left: -2px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
}

.switchEnabled {
  background: var(--primary-color);
  border-color: #var(--primary-color);
  justify-content: flex-end;
}
