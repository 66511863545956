.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

body {
  overflow: hidden;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.nav-tabs {
  margin-top: 10px !important;
}

.btn-success{
  border-width: 0 !important;
  border-radius: 0 !important;
}

.btn-secondary{
  border-width: 0 !important;
  border-radius: 0 !important;
}

.iconBack{
  text-align: left;
}

.timeCounter{
  text-align: right;
}

.endAudit .btn-success{
  background: #EF7359 !important;
}

.subQuestion {
  margin-left: 10px;
  margin-top: 5px;
}

.iconPlay{
  background: #99CC33;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  margin-left: 3px;
  padding-top: 3px;
}

.iconTrash{
  background: #EF5959;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  margin-left: 10px;
  padding-top: 3px;
}

.iconBackground{
  background: #99CC33;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-left: 30px;
}

.microphone{
  margin-left: 0 !important;
}
.sizeIcon{
  width: 30px;
  height: 30px;
  background: #99CC33;
  border-radius: 50%;
  margin-top: 5px;
}

.noInputBorder {
  border: 0;
}

.questionText{
  width: 100%;
}

.radioButton{
  text-align: center
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.legend {
  width: 100%;
  margin-left: 15px;
}

.legend-item {
  float: left;
  height: 10px;
}

ul {
  padding-inline-start: 0px
}

button:focus{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

textarea:focus{
  border: none !important;
}