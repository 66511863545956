body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  --primary-color: #99cc33;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

body {
  overflow: hidden;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.nav-tabs {
  margin-top: 10px !important;
}

.btn-success{
  border-width: 0 !important;
  border-radius: 0 !important;
}

.btn-secondary{
  border-width: 0 !important;
  border-radius: 0 !important;
}

.iconBack{
  text-align: left;
}

.timeCounter{
  text-align: right;
}

.endAudit .btn-success{
  background: #EF7359 !important;
}

.subQuestion {
  margin-left: 10px;
  margin-top: 5px;
}

.iconPlay{
  background: #99CC33;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  margin-left: 3px;
  padding-top: 3px;
}

.iconTrash{
  background: #EF5959;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  margin-left: 10px;
  padding-top: 3px;
}

.iconBackground{
  background: #99CC33;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-left: 30px;
}

.microphone{
  margin-left: 0 !important;
}
.sizeIcon{
  width: 30px;
  height: 30px;
  background: #99CC33;
  border-radius: 50%;
  margin-top: 5px;
}

.noInputBorder {
  border: 0;
}

.questionText{
  width: 100%;
}

.radioButton{
  text-align: center
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.legend {
  width: 100%;
  margin-left: 15px;
}

.legend-item {
  float: left;
  height: 10px;
}

ul {
  -webkit-padding-start: 0px;
          padding-inline-start: 0px
}

button:focus{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

textarea:focus{
  border: none !important;
}
.tabBarAuditIntro_navStyle__O_bkq {
  background: #eaeaea;
}
.tabBarAuditIntro_tabStyle__33YSi {
  background: #ffffff;
}

.tabBarAuditIntro_languageSelector__220CY {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Switch_switch__pt7at {
  border: 2px solid #ccc;
  border-radius: 20px;
  width: 60px;
  height: 28px;
  background: white;
  display: flex;
}

.Switch_switchSelector__GC0dx {
  border-radius: 20px;
  background: white;
  border: 1px solid #c0c0c0;
  height: 28px;
  width: 28px;
  margin-top: -2px;
  margin-left: -2px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
}

.Switch_switchEnabled__3MzpG {
  background: var(--primary-color);
  border-color: #var(--primary-color);
  justify-content: flex-end;
}

#flyoutMenu {
  width: 100vw;
  height: 100vh;
  background-color: rgb(204, 204, 204);
  background: rgb(204, 204, 204);
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1), -webkit-transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  overflow: scroll;
  z-index: 1000;
}

#flyoutMenu.hide {
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
}

#flyoutMenu.show {
  -webkit-transform: translate3d(0vw, 0, 0);
          transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}

#flyoutMenu h2 a {
  color: #333;
  margin-left: 15px;
  text-decoration: none;
}

#flyoutMenu h2 a:hover {
  text-decoration: underline;
}

